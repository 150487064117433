import {
  animate,
  scroll,
  inView,
  stagger,
  timeline,
  glide,
  spring,
} from "motion";

function animateBlogPosts() {
  let delayIndex = 0;
  inView(
    ".post__preview",
    ({ target }) => {
      // Blogpost container
      animate(
        target,
        { y: [75, 0], opacity: 1 },
        {
          duration: 0.4,
          delay: delayIndex * 0.25,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
      delayIndex += 1;
    },
    { amount: 0.15 }
  );

  window.addEventListener("scroll", () => {
    delayIndex = 0; // Zurücksetzen des Indexes nach jedem Scrollen
  });
}

export function animateMobileMenu(x_offset = -100) {
  const sequence = [
    [
      ".site-branding",
      { x: [x_offset, 0], opacity: [0, 1] },
      { duration: 0.3 },
    ],
    [
      "#primary-menu li",
      { y: [115, 0], opacity: [0, 1] },
      {
        duration: 0.325,
        delay: stagger(0.075),
        easing: [0.22, 0.03, 0.26, 1],
        at: "-0.2",
      },
    ],
  ];

  timeline(sequence);
}

export function animateGenericElements() {
  let genericAnimationIndex = 0;

  inView(
    ".animation__generic",
    (info) => {
      const delay = 1 * 0.25;

      animate(
        info.target,
        { x: [-75, 0], opacity: 1 },
        {
          duration: 0.5,
          delay: genericAnimationIndex * 0.25,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
      genericAnimationIndex += 1;
    },
    { amount: 0.75 }
  );

  window.addEventListener("scroll", () => {
    genericAnimationIndex = 0; // Zurücksetzen des Indexes nach jedem Scrollen
  });
}

document.addEventListener("DOMContentLoaded", function () {
  animateBlogPosts();
  animateGenericElements();

  inView(
    ".intro-image",
    ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        {
          delay: stagger(0.125),
          duration: 0.5,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
      try {
        animate(
          ".content-inner .anim--appear",
          { opacity: 1, x: [-100, 0] },
          {
            delay: stagger(0.125, { start: 0.2 }),
            duration: 0.35,
            easing: [0.22, 0.03, 0.26, 1],
          }
        );
      } catch (error) {}
    },
    { amount: 0.65 }
  );

  inView(
    ".claim-text",
    ({ target }) => {
      animate(
        target.querySelectorAll("h1, p"),
        { opacity: 1, transform: "none" },
        {
          delay: stagger(0.125, { start: 0.3 }),
          duration: 0.5,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.4 }
  );

  inView(
    ".profile-slider",
    ({ target }) => {
      animate(
        ".profile-slider .anim__appear--left",
        { opacity: 1, transform: "none" },
        {
          delay: stagger(0.075),
          duration: 0.6,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.45 }
  );

  inView(
    "#wpforms-100",
    ({ target }) => {
      animate(
        target.querySelectorAll(".wpforms-field"),
        { y: [75, 0], opacity: [0, 1] },
        {
          delay: stagger(0.08125),
          duration: 0.2,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.25 }
  );

  inView(
    ".hero-strategy .anim--appear",
    ({ target }) => {
      animate(
        target,
        { opacity: 1, transform: "none" },
        {
          delay: stagger(0.175),
          duration: 0.53,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.7 }
  );

  // SERVICE PAGE
  let serviceBoxIntervalIndex = 0.25;

  inView(
    ".profile-box",
    ({ target }) => {
      try {
        animate(
          target.querySelector(".profile__image-container"),
          { opacity: 1, transform: "none" },
          {
            delay: serviceBoxIntervalIndex * 0.175,
            duration: 0.53,
            easing: [0.22, 0.03, 0.26, 1],
          }
        );

        animate(
          target.querySelectorAll(".anim--appear"),
          { opacity: 1, transform: "none" },
          {
            delay: serviceBoxIntervalIndex * 0.175,
            duration: 0.53,
            easing: [0.22, 0.03, 0.26, 1],
          }
        );

        serviceBoxIntervalIndex += 1;
      } catch (error) {}
    },
    { amount: 0.65 }
  );

  inView(
    ".call-to-action",
    ({ target }) => {
      animate(
        target,
        { opacity: 1 },
        {
          delay: stagger(0.175),
          duration: 0.4,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );

      animate(
        target.querySelectorAll(".anim--appear"),
        { opacity: 1, x: [-50, 0] },
        {
          delay: stagger(0.175, { start: 0.25 }),
          duration: 0.5,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.2 }
  );

  // ZUR PERSON

  inView(
    ".zur-person",
    ({ target }) => {
      animate(
        target.querySelectorAll("img"),
        { opacity: 1, x: [-75, 0] },
        {
          delay: stagger(0.125),
          duration: 0.25,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );

      animate(
        target.querySelectorAll(".detail, h2"),
        { x: [-50, 0], opacity: 1 },
        {
          delay: stagger(0.125),
          duration: 0.25,
          easing: [0.22, 0.03, 0.26, 1],
        }
      );
    },
    { amount: 0.3 }
  );
});
