import Splide from "@splidejs/splide";
import { animate } from "motion";
import "./animations.js";
import "./navigation.js";

let map;

async function initMap() {
  const position = { lat: 50.93212549619841, lng: 11.603289031695507 };
  // Request needed libraries.
  //@ts-ignore
  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  var style = [
    {
      stylers: [{ hue: "#bda500" }, { saturation: -75 }],
    },
  ];

  // The map, centered at Uluru
  map = new Map(document.getElementById("map"), {
    zoom: 14,
    center: position,
    mapTypeId: "terrain",
    scaleControl: false,
    mapTypeControl: false,
    scrollwheel: false,
    streetViewControl: false,
    styles: style,
  });

  new google.maps.Marker({
    position,
    map: map,
  });
}

function topFunction() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

document
  .querySelector(".page-top-teleporter svg")
  .addEventListener("click", topFunction);

document.addEventListener("change", (event) => {
  console.log(event);
});

document.addEventListener("DOMContentLoaded", function () {
  if (document.getElementById("map")) initMap();
  const shareLinks = document.querySelectorAll(".cta-sharing");

  shareLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      const url = link.getAttribute("data-value");

      navigator.clipboard
        .writeText(url)
        .then(() => {
          animate(
            link.querySelector(".sharing-popup"),
            { opacity: 1, x: [25, 0], visibility: "visible" },
            {
              duration: 0.25,
              easing: [0.22, 0.03, 0.26, 1],
            }
          );

          setTimeout(() => {
            animate(
              link.querySelector(".sharing-popup"),
              { opacity: 0, x: [0, 25], visibility: "hidden" },
              {
                duration: 0.25,
                easing: [0.22, 0.03, 0.26, 1],
              }
            );
          }, 1500);
        })
        .catch((err) => {
          console.error("Fehler beim Kopieren: ", err);
        });
    });
  });

  // Code to be executed when the DOM is ready
  let sliderElements = document.getElementsByClassName("splide");
  if (sliderElements.length) {
    new Splide(".splide", {
      // type: "loop",
      gap: 20,
      arrows: false,
      interval: 5000,

      mediaQuery: "min",
      breakpoints: {
        550: {
          perPage: 2,
          gap: 20,
        },
        600: {
          gap: 40,
        },
        780: {
          perPage: 3,
          gap: 20,
        },
        880: {
          gap: 40,
        },
      },
    }).mount();
  }
});
